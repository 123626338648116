import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Nav from 'react-bootstrap/Nav';

import './HeaderNav.scss';
import HeaderNavDropdown from './HeaderNavDropdown';

const HeaderNav: FC = () => {
  const {
    menu: { navigation },
  } = useStaticQuery(
    graphql`
      query {
        menu {
          navigation {
            properties {
              label
              url {
                url
                name
              }
            }
          }
        }
      }
    `
  );

  return (
    <Nav data-testid="header" className="header__nav-content">
      {navigation.map(({ properties }) => {
        const { url, label } = properties;

        return url?.length > 1 ? (
          <HeaderNavDropdown key={`HeaderNavDropdown-${label}`} dropdown={properties} />
        ) : (
          <Nav.Link href={`${url[0].url}`} key={`HeaderLink-${label}`}>
            {label}
          </Nav.Link>
        );
      })}
    </Nav>
  );
};

export default HeaderNav;
